import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Route from './pages/Route';

const config = {
    phone : '514.476.0669',
    email : 'victor.vremea@gmail.com'
};

function App() {
  return (
    <div className="App">
      <Route/>
    </div>
  );
}

export default App;
