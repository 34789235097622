import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';

const Home = lazy(() => import('./Home/'));
const About = lazy(() => import('./About/'));
const ShowCase = lazy(() => import('./ShowCase/'));
const Services = lazy(() => import('./Services/'));
const GetQuote = lazy(() => import('./GetQuote/'));
const Contact = lazy(() => import('./Contact/'));

const App = () => (
    <Router>
        <Suspense fallback={<div id="loading-screen">Loading...</div>}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/finishing" element={<ShowCase />} />
                <Route path="/preparation" element={<ShowCase />} />
                <Route path="/services" element={<Services />} />
                <Route path="/get-a-quote" element={<GetQuote />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </Suspense>
    </Router>
);

export default App;
